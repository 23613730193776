import * as React from "react"
import * as THREE from 'three';
import Layout from "../components/Layout";
import { Loading } from "../components/Loading";

import '../styles/index.scss';

import profile from '../images/profile.jpg'
import github from '../images/github.png'
import linkedin from '../images/linkedin.png'
// import mail from '../images/mail.png'
import { Link } from "gatsby";
import SEO from "../components/SEO";

const WorkPage = () => {
  return (
    <Layout> 
      <nav style={{ margin: '3em auto 0 auto' }}>
          <Link to='/'>Main</Link>
          <Link to='/about'>About</Link>
          <Link to='/contact'>Contact</Link>
      </nav>
        <main>
            <header>
                <h1>Work/Portfolio</h1>
            </header>

            <article className="me-info">
               <p>coming soon</p>
            </article>
        </main>
    </Layout>
  )

}

export default WorkPage
